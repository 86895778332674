import { FC, useCallback, useMemo } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { MergedRequisition } from '../../../../../Data/Requisition';
import RequisitionProduct from '../../../../../Data/RequisitionProduct';
import { dictionary } from '../../../../../dictionary';
import { TableHeaderRow } from '../../../../../Shared/ReactTable/TableHeaderRow';
import { useTableStyles } from '../../../../../Themes/table.styles';
import useUtilityStyles from '../../../../../Themes/utility.styles';
import { joinArgs } from '../../../../../Utils/arrayUtils';
import { AddCapitationsButton } from '../AddCapitationsButton';
import { useProductsToBillTableStyles } from '../productsToBill.styles';
import { ProductsToBillExpandRowButton } from '../shared/ProductsToBillExpandRowButton';
import { useReqProductTable } from './individualProductsTable.utils';
import { IndividualReqProductRow } from './IndividualReqProductRow';
import { If } from '../../../../../Shared/If';

export interface IndividualProductsTableProps {
  editable: boolean;
  requisition: MergedRequisition;
  addCapitationOnClick?: (productIndices: number[]) => void;
  setProductInEditInfo: (info: [RequisitionProduct, number | undefined, number] | undefined) => void;
  capitationsApplicable: boolean;
  hospHasProductCategories: boolean;
}

export const IndividualProductsTable: FC<IndividualProductsTableProps> = (props) => {
  const { editable, requisition, addCapitationOnClick, setProductInEditInfo, capitationsApplicable, hospHasProductCategories } = props;

  const classes = { ...useTableStyles(), ...useProductsToBillTableStyles() };
  const utilClasses = useUtilityStyles();
  const products = useMemo(() => (requisition.products || []), [requisition.products]);

  const onProductRowEditClick = useCallback((index: number) => {
    setProductInEditInfo([products[index], undefined, index]);
  }, [products, setProductInEditInfo]);
  const tableContext = useReqProductTable(products, editable, onProductRowEditClick, requisition, hospHasProductCategories, capitationsApplicable);

  return (
    <>
      <Row>
        <Col xs={6}>
          <h3>
            {dictionary.REQ_PRODUCTS_TO_BILL_INDV_TABLE_HEADER}
            <span className={joinArgs(utilClasses.ml05)}>
              (
              {tableContext.rows.length}
              )
            </span>
          </h3>
        </Col>
        <Col xs={6} className={joinArgs(utilClasses.flex, utilClasses.flexEnd, utilClasses.alignCenter)}>
          <ProductsToBillExpandRowButton tableContext={tableContext} />
        </Col>
      </Row>
      <div className={classes.indvRoot}>
        <table {...tableContext.getTableProps()} className={joinArgs(classes.root, utilClasses.mb1)}>
          <thead>
            <TableHeaderRow headerGroups={tableContext.headerGroups} />
          </thead>
          <tbody {...tableContext.getTableBodyProps()}>
            {tableContext.rows.map(row => {
              tableContext.prepareRow(row);
              return (
                <IndividualReqProductRow
                  key={row.id}
                  tableContext={tableContext}
                  row={row}
                  editable={editable}
                  requisition={requisition}
                />
              );
            })}
          </tbody>
          <If condition={editable}>
            <tfoot>
              <tr>
                <td colSpan={tableContext.allColumns.length}>
                  <AddCapitationsButton
                    ungroupedTableContext={tableContext}
                    editable={editable}
                    capitationsApplicable={capitationsApplicable}
                    addCapitationOnClick={addCapitationOnClick}
                  />
                </td>
              </tr>
            </tfoot>
          </If>
        </table>
      </div>
    </>
  );
};
