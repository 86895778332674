import { FC } from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { createUseStyles } from 'react-jss';
import { dictionary } from '../../../dictionary';
import { Modal } from '../../../Shared/modals/Modal/Modal';
import { Button } from '../../../Shared/buttons/Button';

interface ManuallyArchiveReqModalProps {
  cancelOnClick: () => void;
  isModalOpen: boolean;
  manuallyArchiveReq: (id: number) => void;
  reqId: number | undefined;
}

export const ManuallyArchiveReqModal: FC<ManuallyArchiveReqModalProps> = ({
  cancelOnClick,
  isModalOpen,
  manuallyArchiveReq,
  reqId }) => {
  const ManuallyArchiveReqModalStyles = createUseStyles({
    message: {
      borderBottom: '0.09rem solid #d8d8d8',
      paddingBottom: '1rem',
    },
  });

  const classes = ManuallyArchiveReqModalStyles();

  return (
    <>
      <Modal
        data-testid="update-information-modal"
        title="Archive Requisition"
        isOpen={isModalOpen}
        icon={faInfoCircle}
        actionsContent={(
          <>
            <Button buttonStyle="reverse" onClick={cancelOnClick} data-testid="modal-cancel">
              {dictionary.CANCEL}
            </Button>
            <Button buttonStyle="primary" onClick={() => manuallyArchiveReq(reqId!)} data-testid="modal-continue">
              {dictionary.MANUALLY_ARCHIVE_REQ_CONFIRMATION}
            </Button>
          </>
        )}
      >
        <h3 className={classes.message}>
          { dictionary.MANUALLY_ARCHIVE_REQ_MODAL_QUESTION }
        </h3>
        <p><em>{ dictionary.MANUALLY_ARCHIVE_REQ_MODAL_INFO }</em></p>
      </Modal>
    </>
  );
};
