import { useState, useEffect, FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col } from 'react-flexbox-grid';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '../../../Shared/PageHeader/PageHeader';
import { ApiRequisition } from '../../../Data/Requisition';
import { fetchRequisitionById, requisitionApiService } from '../../../Services/RequisitionService';
import { dictionary } from '../../../dictionary';
import Notes from './Notes/Notes';
import { routes } from '../../../Utils/routes';
import { LinkButton } from '../../../Shared/buttons/LinkButton';
import { ReqProcedureInfoDisplay } from '../shared/ReqProcedureInfoDisplay';
import { ReqCasePatientInfoDisplay } from '../shared/ReqCasePatientInfoDisplay';
import { ReqBillingInfoDisplay } from '../shared/ReqBillingInfoDisplay';
import { joinArgs } from '../../../Utils/arrayUtils';
import { useUtilityStyles } from '../../../Themes/utility.styles';
import { If } from '../../../Shared/If';
import { ReqDetailsProductInfo } from './ReqDetailsProductInfo';
import { ReqDetailsApprovalActions } from './ReqDetailsApprovalActions';
import { ReqStatusBadge } from '../../../Shared/ReqStatusBadge/ReqStatusBadge';
import { BackButton } from '../../../Shared/buttons/BackButton';
import { Button } from '../../../Shared/buttons/Button';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { NotificationType } from '../../../redux/initialState';
import { RequisitionStatus } from '../../../Data/RequisitionStatus';
import { getDisplayStatus } from '../shared/requisition.utils';
import { ProductCategoryStub } from '../../../Data/ProductCategory';
import { hospitalService } from '../../../Services/HospitalService';
import { costCenterService } from '../../../Services/CostCenterService';
import { RequisitionUpdateRequest } from '../../../Data/RequisitionRequest';
import { ManuallyArchiveReqModal } from './ManuallyArchiveReqModal';
import { ManuallyDeleteReqModal } from './ManuallyDeleteReqModal';
import { currentUserIsAdminForAtLeastOneHospital, currentUserSelector, currentUserIsCSRForHospital } from '../../../redux/user/userSelectors';
import { userRoles } from '../../../Utils/userRoles';

export const RequisitionDetails: FC = () => {
  const { id } = useParams<{ id?: string }>();
  const [requisition, setRequisition] = useState<ApiRequisition>();
  const utilClasses = useUtilityStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const goToReqIndex = () => history.push(routes.requisitions.index);
  const [hospitalProductCategories, setHospitalProductCategories] = useState<ProductCategoryStub[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const isAdmin = useSelector(currentUserIsAdminForAtLeastOneHospital);
  const user = useSelector(currentUserSelector);
  const isVendor = user?.roleName === userRoles.vendor;
  const currentUserIsCSR = useSelector(currentUserIsCSRForHospital(requisition?.hospital?.id ?? 0));

  useEffect(() => {
    (async () => {
      if (requisition?.department && !requisition.costCenter) {
        const costCenterResponse = await costCenterService.getByDepartment(requisition.department.id) || [];
        if (costCenterResponse!.length === 1) {
          requisition.costCenter = costCenterResponse[0]; // eslint-disable-line
          const reqUpdateRequest: RequisitionUpdateRequest = {
            id: requisition.id,
            procedureId: requisition.procedure.id,
            physicianId: requisition.physician.id,
            patientId: requisition.patient.id,
            caseNumber: requisition.caseNumber,
            caseDate: requisition.caseDate!,
            costCenterId: requisition.costCenter.id,
            subCostCenterId: requisition.subCostCenter?.id || null,
            poNumber: requisition.poNumber || null,
            additionalNotes: requisition.additionalNotes || null,
          };
          requisitionApiService.updateBaseInfo(reqUpdateRequest);
        }
      }
    })();
  }, [requisition]);

  useEffect(() => {
    (async () => setRequisition(await fetchRequisitionById(Number(id))))();
  }, [id]);

  useEffect(() => {
    if (
      !requisition?.canCurrentUserApprove ||
      requisition?.status === RequisitionStatus.declined ||
      requisition?.status === RequisitionStatus.returned ||
      requisition?.poNumber
    ) {
      return;
    }
    (async () => {
      try {
        const pcs = await hospitalService.getProductCategoriesByHealthSystem(requisition!.hospital.healthSystemId);
        setHospitalProductCategories(pcs.healthSystemProductCategories);
        setIsLoading(false);
      } catch (e) { console.error(e); }
    })();
  }, [requisition]);

  if (!requisition?.id) {
    return <p data-testid="requisition-details-loading">{dictionary.REQ_DETAILS_LOADING_REQ}</p>;
  }

  const canSubmit = requisition.status === RequisitionStatus.returned && requisition.canCurrentUserEdit;
  const canArchiveConditionOne = isAdmin && (requisition.status === RequisitionStatus.declined || requisition.status === RequisitionStatus.completed);
  const canArchiveConditionSecond = isVendor && requisition.status === RequisitionStatus.completed;
  const canArchive = canArchiveConditionOne || canArchiveConditionSecond;
  const canDeleteVendor = isVendor && (requisition.status === RequisitionStatus.draft
    || requisition.status === RequisitionStatus.declined || requisition.status === RequisitionStatus.returned);
  const canDeleteCSM = currentUserIsCSR && (requisition.status === RequisitionStatus.draft
    || requisition.status === RequisitionStatus.declined || requisition.status === RequisitionStatus.returned);
  const canDelete = isAdmin || canDeleteVendor || canDeleteCSM;

  const onSubmit = async () => {
    try {
      await requisitionApiService.resubmit(requisition.id);
      goToReqIndex();
    } catch {
      dispatch(addNotification(NotificationType.error, dictionary.REQ_SUBMISSION_ERROR));
    }
  };

  const cancelOnArchiveClick = () => setIsArchiveModalOpen(false);
  const cancelOnDeleteClick = () => setIsDeleteModalOpen(false);

  const manuallyDeleteReq = async (reqId: number | undefined) => {
    if (reqId) {
      try {
        await requisitionApiService.delete(reqId);
        dispatch(addNotification(NotificationType.success, dictionary.DELETE_REQ_SUCCESS));
        goToReqIndex();
      } catch (exception) {
        dispatch(addNotification(NotificationType.error, dictionary.STANDARD_ERROR));
      }
    }
    setIsDeleteModalOpen(false);
  };

  const manuallyArchiveReq = async (reqId: number | undefined) => {
    if (reqId) {
      try {
        await requisitionApiService.updateReqStatus(reqId);
        dispatch(addNotification(NotificationType.success, dictionary.ARCHIVE_REQ_SUCCESS));
        goToReqIndex();
      } catch (exception) {
        dispatch(addNotification(NotificationType.error, dictionary.STANDARD_ERROR));
      }
    }
    setIsArchiveModalOpen(false);
  };

  return (
    <>
      <PageHeader title={`${dictionary.REQ_VIEW_HEADER}: ${requisition.id}`}>
        <span className={utilClasses.fs21}>
          {`${dictionary.REQ_VIEW_DOCUMENT_NUMBER}: ${requisition.id}`}
        </span>
      </PageHeader>

      <Row className={utilClasses.mb1}>
        <Col xs={3}>
          <ReqStatusBadge status={getDisplayStatus(requisition)} large />
        </Col>
        <Col xs={9} className={joinArgs(utilClasses.flex, utilClasses.flexEnd)}>
          <If condition={requisition.canCurrentUserEdit}>
            <LinkButton
              to={routes.requisitions.edit(requisition.id)}
              data-testid="requisition-details-edit-button"
              leadingIcon={faEdit}
            >
              {dictionary.REQ_DETAILS_EDIT_BUTTON}
            </LinkButton>
          </If>
          <If condition={canDelete}>
            &nbsp; &nbsp;
            <Button className={joinArgs(utilClasses.mr1)} onClick={() => setIsDeleteModalOpen(true)}>{dictionary.MANUALLY_DELETE_REQ_BUTTON}</Button>
          </If>
          <If condition={canArchive}>
            <Button onClick={() => setIsArchiveModalOpen(true)}>{dictionary.MANUALLY_ARCHIVE_REQ_BUTTON}</Button>
          </If>
        </Col>
      </Row>

      <Row>
        <Col xs={4}><ReqProcedureInfoDisplay requisition={requisition} /></Col>
        <Col xs={4}><ReqCasePatientInfoDisplay requisition={requisition} /></Col>
        <Col xs={4}><ReqBillingInfoDisplay requisition={requisition} /></Col>
      </Row>

      <ReqDetailsProductInfo req={requisition} hospHasProductCategories={!!hospitalProductCategories.length} />

      <Row>
        <Col xs={12}><Notes historyNotes={requisition.history ?? []} /></Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div data-testid="action-buttons" className={joinArgs(utilClasses.flex, utilClasses.spaceBetween, utilClasses.mt5)}>
            <BackButton onClick={goToReqIndex} />

            <If condition={canSubmit}>
              <Button onClick={onSubmit}>{dictionary.REQ_DETAILS_RESUBMIT_BUTTON}</Button>
            </If>

            <ReqDetailsApprovalActions req={requisition} hospitalProductCategories={hospitalProductCategories} isLoading={isLoading} />
          </div>
        </Col>
      </Row>
      <ManuallyDeleteReqModal
        cancelOnClick={cancelOnDeleteClick}
        isModalOpen={isDeleteModalOpen}
        manuallyDeleteReq={manuallyDeleteReq}
        reqId={requisition.id}
      />
      <ManuallyArchiveReqModal
        cancelOnClick={cancelOnArchiveClick}
        isModalOpen={isArchiveModalOpen}
        manuallyArchiveReq={manuallyArchiveReq}
        reqId={requisition.id}
      />
    </>
  );
};
