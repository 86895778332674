import { FormikState } from 'formik';
import { MergedRequisition } from '../../../../../Data/Requisition';
import { ManufacturerBase } from '../../../../../Data/Manufacturer';
import { HospitalProduct } from '../../../../../Data/HospitalProduct';
import RequisitionProduct from '../../../../../Data/RequisitionProduct';
import { HospitalManufacturerContract, HospitalManufacturerContractProduct } from '../../../../../Data/Contract';
import { getNextId } from '../../../../../Utils/idUtils';
import { createDescriptor, getFieldDescriptorMap } from '../../../../../Utils/formik.utils';
import { dictionary } from '../../../../../dictionary';
import { maximumProductsPerRequisition } from '../../../../../Utils/Constants';
import { UserBase } from '../../../../../Data/User';

export interface ProductInfoFormValues {
  manufacturer: ManufacturerBase | null;
  hospitalProduct: HospitalProduct | null;
  serialNumber: string;
  lotNumber: string;
  quantity: number;
  explantDiscountApplied: boolean;
  trialDiscountApplied: boolean;
  wastedDiscountApplied: boolean;
  discount: number;
  price: string;
  vendor: UserBase | null;
}

export const hasMaximumProducts = (req: MergedRequisition): boolean => !!req.products && req.products.length >= maximumProductsPerRequisition;

export const createReqProductFormFields = getFieldDescriptorMap<ProductInfoFormValues>([
  createDescriptor({ name: 'manufacturer', label: dictionary.REQ_MANUFACTURER, required: true }),
  createDescriptor({ name: 'serialNumber', label: dictionary.REQ_PRODUCT_SERIAL_NUM }),
  createDescriptor({ name: 'lotNumber', label: dictionary.REQ_PRODUCT_LOT_NUM }),
  createDescriptor({ name: 'quantity', label: dictionary.REQ_PRODUCT_QUANTITY, required: true }),
  createDescriptor({ name: 'price', label: dictionary.REQ_PRODUCT_PRICE, required: true }),
  createDescriptor({ name: 'vendor', label: dictionary.REQ_VENDOR_REPRESENTATIVE, required: false }),
]);

export const areProductsOnRequisition = (editRequisition: MergedRequisition): boolean => {
  const allProducts = [...editRequisition.products ?? [], ...editRequisition.capitations ?? []];

  return allProducts.length > 0;
};

export const defaultValuesWithManufacturer = (manufacturerValue?: ManufacturerBase, vendorValue?: UserBase): ProductInfoFormValues => ({
  manufacturer: manufacturerValue || null,
  vendor: vendorValue || null,
  hospitalProduct: null,
  catalogNumber: '',
  serialNumber: '',
  lotNumber: '',
  // @ts-ignore
  quantity: '',
  explantDiscountApplied: false,
  trialDiscountApplied: false,
  wastedDiscountApplied: false,
  // @ts-ignore
  discount: '',
  price: '',
});

export const addProductToRequisition = (requisition: MergedRequisition, newProduct: RequisitionProduct): MergedRequisition => ({
  ...requisition,
  products:
      [
        ...(requisition.products?.reverse() || []),
        newProduct,
      ],
});

const composePartialProductFromFormValues = (values: ProductInfoFormValues, contractProduct?: HospitalManufacturerContractProduct, hospitalProduct?: HospitalProduct) => ({
  key: getNextId().toString(),
  price: Number.parseFloat(values.price),
  lotNumber: values.lotNumber || null,
  quantity: values.quantity,
  serialNumber: values.serialNumber || null,
  explantDiscountApplied: values.explantDiscountApplied,
  trialDiscountApplied: values.trialDiscountApplied,
  wastedDiscountApplied: values.wastedDiscountApplied,
  discount: values.discount > 0 ? values.discount : 0,
  unitOfMeasure: contractProduct?.unitOfMeasure || hospitalProduct?.unitOfMeasure,
  quantityUnitOfMeasure: contractProduct?.quantityUnitOfMeasure || hospitalProduct?.quantityUnitOfMeasure,

});

export interface KeyedReqProductForTable extends RequisitionProduct {
  key: string;
}

export const composeRequisitionProductFromExistingHospitalProduct = (
  values: ProductInfoFormValues, contractProduct?: HospitalManufacturerContractProduct, contractIdentifier?: string, hospitalProduct?: HospitalProduct
): KeyedReqProductForTable | null => {
  const hospProd = hospitalProduct || values.hospitalProduct;
  if (!hospProd) return null;

  return {
    ...hospProd,
    ...composePartialProductFromFormValues(values, contractProduct, hospitalProduct),
    healthSystemProductId: hospProd.id,
    productCategoryName: hospProd.productCategoryName,
    contractProductId: typeof (contractProduct?.price) === 'number' ? hospProd.contractProductId : undefined,
    contractPrice: contractProduct?.price,
    contractIdentifier,
    id: undefined,
  };
};

export const calculateHighestDiscount = (
  explantDiscountApplied: boolean,
  trialDiscountApplied: boolean,
  wastedDiscountApplied: boolean,
  hospitalProduct: HospitalProduct | null,
  activeContracts: HospitalManufacturerContract[]
): number => {
  if (!activeContracts.length || !hospitalProduct) {
    return 0;
  }

  const contractForSelectedProduct = activeContracts.find(c => c.products.find(cp => cp.id === hospitalProduct.contractProductId));
  if (!contractForSelectedProduct) {
    return 0;
  }

  const discounts: number[] = [
    explantDiscountApplied ? contractForSelectedProduct.explantDiscount : 0,
    trialDiscountApplied ? contractForSelectedProduct.trialDiscount : 0,
    wastedDiscountApplied ? contractForSelectedProduct.wastedDiscount : 0,
  ];

  return Math.max(...discounts);
};

export const getActiveContractProduct = (
  hospitalProduct: HospitalProduct | null, activeContracts: HospitalManufacturerContract[]
): [HospitalManufacturerContract | undefined, HospitalManufacturerContractProduct | undefined] => {
  if (!activeContracts || !activeContracts.length || !hospitalProduct) {
    return [undefined, undefined];
  }
  const matchingContract = activeContracts
    .find(c => c.products.some(cp => cp.id === hospitalProduct.contractProductId));
  const matchingProduct = matchingContract?.products.find(cp => cp.id === hospitalProduct.contractProductId);

  return [matchingContract, matchingProduct];
};

export const resetFormExceptManufacturer = (
  values: ProductInfoFormValues,
  resetForm: (nextState?: Partial<FormikState<ProductInfoFormValues>> | undefined) => void
): void => {
  resetForm({ values: defaultValuesWithManufacturer(values.manufacturer || undefined) });
};

export const resetFormExceptManufacturerAndVendor = (
  values: ProductInfoFormValues,
  resetForm: (nextState?: Partial<FormikState<ProductInfoFormValues>> | undefined) => void
): void => {
  resetForm({ values: defaultValuesWithManufacturer(values.manufacturer || undefined, values.vendor || undefined) });
};

export const composeRequisitionForTempProduct = (values: ProductInfoFormValues): MergedRequisition => ({
  manufacturer: values.manufacturer || undefined,
  tempProduct: { ...values },
});

type HandleBlur = { (e: React.FocusEvent<any>): void; <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void; };

export const handleBlurDiscount = (values: ProductInfoFormValues, form: any, handleBlur: HandleBlur): ((event: React.FocusEvent<HTMLInputElement>) => void) | undefined => event => {
  const newValue = event.currentTarget.value;

  if (!!(values.explantDiscountApplied || values.trialDiscountApplied || values.wastedDiscountApplied) && newValue && !Number.isNaN(newValue)) {
    form.setFieldValue('discount', parseFloat(newValue).toFixed(2));
  } else {
    form.setFieldValue('discount', '0.00');
  }

  handleBlur(event);
};

export const handleChangeDiscount =
(setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  values: ProductInfoFormValues, form: any):
  ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined => (event) => {
  const newValue = event.currentTarget.value;

  setFieldValue('discount', newValue);

  if (!(values.explantDiscountApplied || values.trialDiscountApplied || values.wastedDiscountApplied) && newValue) {
    form.setFieldValue('discount', '');
  }
};
