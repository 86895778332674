import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableOptions, UseExpandedOptions, UseRowSelectOptions, Column, TableInstance, UseExpandedInstanceProps, useTable, useExpanded, useRowSelect } from 'react-table';
import { MergedRequisition } from '../../../../../Data/Requisition';
import RequisitionProduct from '../../../../../Data/RequisitionProduct';
import { dictionary } from '../../../../../dictionary';
import { actionTypes } from '../../../../../redux/actionTypes';
import { currentUserSelector } from '../../../../../redux/user/userSelectors';
import { CheckboxCell } from '../../../../../Shared/tables/CheckboxCell';
import { ExpandCell } from '../../../../../Shared/tables/ExpandCell';
import { getActionsCellComponent } from '../../../../../Shared/tables/getActionsCellComponent';
import { toCurrency, displayPrice } from '../../../../../Utils/formatNumber';
import { userRoles } from '../../../../../Utils/userRoles';
import { getTranTypeString } from '../../EditReqProductModal/editReqProduct.utils';
import { KeyedReqProductForTable } from '../../ProductInfo/shared/ProductInfo.utils';
import { getRequisitionUniquePoNumberCount, isStatusApprovedOrCompleted } from '../../requisition.utils';
import { renderRequisitionProductPoNumber } from '../RequisitionProductPoNumberBadge';
import { renderRequisitionProductPrice } from '../Badges/RequisitionProductPriceBadge';
import { renderRequisitionProductCategory } from '../Badges/ProductCategoryDisplay';
import { buildTableCols } from '../../../../../Shared/tables/tableUtils';

type UngroupedProductsOptions = TableOptions<KeyedReqProductForTable> & UseExpandedOptions<KeyedReqProductForTable> & UseRowSelectOptions<KeyedReqProductForTable>;

const valueOrEmpty = (value?: string | null) => value || dictionary.EMPTY_FIELD_MARK;

export type ReqProductsTableInstance = TableInstance<KeyedReqProductForTable> & UseExpandedInstanceProps<KeyedReqProductForTable>;

export const useReqProductTable = (
  products: RequisitionProduct[],
  editable: boolean,
  onEditClick: (index: number) => void,
  requisition: MergedRequisition,
  hospHasProductCategories: boolean,
  capitationsApplicable: boolean,
): ReqProductsTableInstance => {
  const dispatch = useDispatch();
  const tableProducts = products as KeyedReqProductForTable[];
  const memoizedProducts = useMemo(() => tableProducts.reverse(), [tableProducts]);
  const getRowId = useMemo(() => (product: KeyedReqProductForTable) => product.key || product.id?.toString() || '', []);
  const currentUser = useSelector(currentUserSelector);
  const userCanSeeExtraColumns = currentUser && currentUser.roleName !== userRoles.vendor;

  const columns: Column<KeyedReqProductForTable>[] = useMemo(() => {
    const onRemoveClick = (rowIndex: number) => dispatch({ type: actionTypes.requisition.removeProduct, productIndex: rowIndex });
    const Actions = getActionsCellComponent([
      { key: 'indvProdExpand', Cell: ExpandCell },
      { keep: editable, key: 'indvEdit', intent: 'primary', title: dictionary.REQ_PRODUCT_EDIT_BTN, icon: faEdit, action: (cellProps) => onEditClick(cellProps.row.index) },
      { keep: editable, key: 'indvRemove', intent: 'danger', title: dictionary.REQ_PRODUCT_REMOVE_BTN, icon: faTrash, action: (cellProps) => onRemoveClick(cellProps.row.index) },
    ]);
    const reqHasMultiplePoNumbers = getRequisitionUniquePoNumberCount(requisition) > 1;
    const showProdCategory = userCanSeeExtraColumns && !isStatusApprovedOrCompleted(requisition.status);
    const contractPriceAccessor = (rowProduct: KeyedReqProductForTable) => valueOrEmpty(toCurrency(rowProduct.contractPrice));
    const subtotalAccessor = (rowProduct: KeyedReqProductForTable) => displayPrice(rowProduct.price, rowProduct.quantity, rowProduct.discount);
    const productCategoryAccessor = (rowProduct: KeyedReqProductForTable) => renderRequisitionProductCategory(rowProduct, hospHasProductCategories);
    const showCheckbox = editable && capitationsApplicable;

    const cols = buildTableCols<KeyedReqProductForTable>([
      { keep: showCheckbox, id: 'selection', className: 'cellAlignCenter', Cell: CheckboxCell, width: '4.5rem' },
      { Header: dictionary.REQ_PRODUCTS_TO_BILL_SEQUENCE_NUMBER, accessor: (_row, rowIndex) => rowIndex + 1, width: '4.5rem' },
      { Header: dictionary.REQ_PRODUCTS_TO_BILL_CATALOG_NUMBER, accessor: 'catalogNumber', width: '10%' },
      { Header: dictionary.REQ_PRODUCT_DESCRIPTION, accessor: 'description' },
      { Header: dictionary.REQ_PRODUCTS_TO_BILL_SERIAL_NUMBER, accessor: row => valueOrEmpty(row.serialNumber) },
      { Header: dictionary.REQ_PRODUCTS_TO_BILL_LOT_NUMBER, accessor: row => valueOrEmpty(row.lotNumber) },
      { Header: dictionary.REQ_PRODUCTS_TO_BILL_QUANTITY, accessor: row => valueOrEmpty(row.quantity?.toString()) },
      { keep: reqHasMultiplePoNumbers, Header: dictionary.REQ_PO_NUM, accessor: row => renderRequisitionProductPoNumber(row.poNumber) },
      { keep: userCanSeeExtraColumns, Header: dictionary.REQ_PRODUCT_ITEM_ID, accessor: row => valueOrEmpty(row.orderIdentifier) },
      { keep: showProdCategory, Header: dictionary.REQ_PRODUCT_PRODUCT_CATEGORY, accessor: productCategoryAccessor },
      { keep: userCanSeeExtraColumns, Header: dictionary.REQ_PRODUCT_CONTRACT_IDENTIFIER, accessor: rowProduct => valueOrEmpty(rowProduct.contractIdentifier) },
      { keep: userCanSeeExtraColumns, Header: dictionary.REQ_PRODUCT_CONTRACT_PRICE, accessor: contractPriceAccessor, className: 'cellAlignRight' },
      { Header: dictionary.REQ_PRODUCT_PRICE, accessor: renderRequisitionProductPrice, className: 'cellAlignRight' },
      { Header: dictionary.REQ_PRODUCT_LINE_TOTAL, className: 'cellAlignRight', accessor: subtotalAccessor, dataTestId: 'subtotal-cell' },
      { id: 'actionsCell', Cell: Actions },
    ]);

    return cols;
  }, [userCanSeeExtraColumns, editable, capitationsApplicable, dispatch, onEditClick, requisition, hospHasProductCategories]);

  const ungroupedTableOptions: UngroupedProductsOptions = { data: memoizedProducts, columns, autoResetExpanded: false, getRowId, autoResetSelectedRows: false };

  return useTable<KeyedReqProductForTable>(
    ungroupedTableOptions,
    useExpanded,
    useRowSelect,
  ) as ReqProductsTableInstance;
};

export const useReqProductSubtable = (product: RequisitionProduct, editable: boolean, requisition: MergedRequisition): TableInstance<RequisitionProduct> => {
  const memoizedProducts = useMemo(() => [product], [product]);
  const currentUser = useSelector(currentUserSelector);
  const userCanSeeExtraColumns = currentUser && currentUser.roleName !== userRoles.vendor;

  const columns = useMemo(() => {
    const showProdCat = isStatusApprovedOrCompleted(requisition.status) && userCanSeeExtraColumns;
    const cols = buildTableCols<RequisitionProduct>([
      { keep: editable, id: 'emptySelect', width: '4.5rem' },
      { keep: showProdCat, Header: dictionary.REQ_PRODUCT_PRODUCT_CATEGORY, colSpan: 2, accessor: row => valueOrEmpty(row.productCategoryName) },
      { Header: dictionary.REQ_PRODUCT_BRAND, accessor: row => valueOrEmpty(row.brandName) },
      { Header: dictionary.REQ_PRODUCT_QUANTITY_UOM, accessor: 'quantityUnitOfMeasure' },
      { Header: dictionary.REQ_PRODUCT_UOM, accessor: 'unitOfMeasure' },
      { Header: dictionary.REQ_PRODUCT_TRANSACTION_TYPE, accessor: row => valueOrEmpty(getTranTypeString(row)) },
      { Header: dictionary.REQ_PRODUCT_DISCOUNT, className: 'cellAlignRight', accessor: reqProd => `${reqProd.discount}%` },
      { keep: !userCanSeeExtraColumns, id: 'emptyLineTotalColumnCell' },
      { id: 'emptyExpand' },
      { keep: editable, id: 'emptyActions' },
    ]);

    return cols;
  }, [editable, requisition.status, userCanSeeExtraColumns]);

  return useTable<RequisitionProduct>({ data: memoizedProducts, columns });
};
