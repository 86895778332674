import { FC } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PageHeader } from '../../Shared/PageHeader/PageHeader';
import { dictionary } from '../../dictionary';
import useUtilityStyles from '../../Themes/utility.styles';
import { routes } from '../../Utils/routes';
import { Button } from '../../Shared/buttons/Button';
import { joinArgs } from '../../Utils/arrayUtils';
import { If } from '../../Shared/If';
import { currentUserRoleSelector } from '../../redux/user/userSelectors';
import { userRoles } from '../../Utils/userRoles';

export const HelpCenter: FC = () => {
  const utilClasses = useUtilityStyles();
  const history = useHistory();
  const navToEditTerms = () => history.push(routes.helpCenter.editTermsOfUse);
  const currentUserRole = useSelector(currentUserRoleSelector);
  const isOmiSupport = currentUserRole === userRoles.omiAdmin;
  // eslint-disable-next-line max-len
  const helpCenterStrings = ['Vendor Account Creation', 'Vendor Application Training', 'Contracts And Pricing Agreements', 'Do not see your manufacturer(s) in the dropdown menu', 'Do not see hospital/locations', 'Unsure how to add products/capitations', 'Patient/Procedures/Physicians', 'Requisition Statuses'];
  return (
    <>
      <PageHeader title={dictionary.HELP_CENTER_HEADER} />
      <Row>
        <Col xs={12} className={joinArgs(utilClasses.flex, utilClasses.alignCenter)}>
          <h2>{dictionary.TERMS_OF_USE_HEADER}</h2>
          <If condition={isOmiSupport}>
            <Button className={utilClasses.ml5} buttonStyle="reverse" onClick={navToEditTerms}>{dictionary.HELP_CENTER_MANAGE_BUTTON}</Button>
          </If>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <div><Link to={routes.helpCenter.termsOfUse}>{dictionary.TERMS_OF_USE_HEADER}</Link></div>
              <div className={utilClasses.mt2}>
                <a href="https://www.owens-minor.com/privacy-policy/" rel="noopener noreferrer" target="_blank">
                  {dictionary.PRIVACY_POLICY_LABEL}
                </a>
              </div>
            </Col>
          </Row>

          <h2>{dictionary.ADDITIONAL_ASSISTANCE_HEADER}</h2>
          <Row>
            <Col xs={12}>
              {dictionary.ADDITIONAL_ASSISTANCE_PRIMARY_TEXT}
            </Col>
          </Row>
          {helpCenterStrings.map((string) => (
            <Row key={string} className={utilClasses.mt05}>
              <Col xs={12}>
                - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {string}
              </Col>
            </Row>
          ))}

          <Row className={utilClasses.mt05}>
            <Col xs={12}>
              <p>
                {dictionary.HELP_CENTER_CONTACT_US}
                {dictionary.QSIGHT_SUPPORT_NUMBER}
                &nbsp;or email&nbsp;
                {dictionary.QSIGHT_SUPPORT_EMAIL}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
