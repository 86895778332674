import { FC, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { Col, Row } from 'react-flexbox-grid';
import { useParams } from 'react-router-dom';
import { FormFlowStepComponent } from '../../../Shared/FormFlow/FormFlow';
import { Contract, ContractProduct } from '../../../Data/Contract';
import { NotificationType, ReduxState } from '../../../redux/initialState';
import { useButtonStyles } from '../../../Themes/button.styles';
import { useProductEditCounts } from '../CreateContract/ProductPricingInfo/useProductUploadCount';
import { addNotification } from '../../../redux/notifications/notificationsActionCreator';
import { joinArgs } from '../../../Utils/arrayUtils';
import useUtilityStyles from '../../../Themes/utility.styles';
import { dictionary } from '../../../dictionary';
import { csvHeadersPlusDelete } from '../../../Utils/contracts/contractUtils';
import { If } from '../../../Shared/If';
import { ExceptionDownloadButton } from '../CreateContract/ProductPricingInfo/ExceptionDownloadButton';
import { getContractById } from '../../../Services/ContractService';
import { UploadEditsInput } from '../CreateContract/ProductPricingInfo/UploadContractInput/UploadEditsInput';
import { actionTypes } from '../../../redux/actionTypes';
import { LoadingSpinner } from '../../../Shared/LoadingSpinner/LoadingSpinner';

export const EditContractProducts: FC<FormFlowStepComponent<Contract>> = ({ onFormStepComplete, FormActionsComponent }) => {
  const dispatch = useDispatch();
  const contractWithEdits = useSelector((state: ReduxState) => state.contract.editContract);
  const utilClasses = useUtilityStyles();
  const buttonClasses = useButtonStyles();

  const params = useParams<{ id: string }>();
  const contractId = Number(params.id);
  const [contract, setContract] = useState<Contract>();

  const contractMatchesCurrentlyEditing = (contractWithEdits.id && contractWithEdits.id === contractId);

  useEffect(() => {
    (async () => {
      if ((!contract && !contractWithEdits.id) || !contractMatchesCurrentlyEditing) {
        if (!contractMatchesCurrentlyEditing) dispatch({ type: actionTypes.contract.clearEditing });
        try {
          const fetchedContract = await getContractById(contractId);
          setContract(fetchedContract);
          dispatch({ type: actionTypes.contract.updateEditing, contract: fetchedContract });
          dispatch({ type: actionTypes.contract.addProductsEditing, newProductsPricing: fetchedContract.products });
        } catch (e) {
          dispatch(addNotification(NotificationType.error, dictionary.CONTRACT_LOAD_ERROR));
        }
      }
    })();
  }, [dispatch, contractId]); // eslint-disable-line

  const { successes: productSuccessCount, errors: productErrorCount, deletes: productDeleteCount } = useProductEditCounts();

  const onNextStepClicked = () => {
    if (productSuccessCount) {
      onFormStepComplete(contractWithEdits);
    } else {
      dispatch(addNotification(NotificationType.error, dictionary.CONTRACT_HAS_NO_PRODUCTS_ERROR_MESSAGE));
    }
  };

  const escapeQuotesInDescription = (products: ContractProduct[]): ContractProduct[] => (
    products.map(product => ({
      ...product,
      description: product.description?.replace(/"/g, '""'), // Escape double quotes
    }))
  );

  return (
    <>
      <Row>
        <Col xs={12} md={8} lg={6}>
          <div className={joinArgs(utilClasses.p1, utilClasses.backgroundNearWhite)}>
            <h4 className={joinArgs(utilClasses.m0, utilClasses.mb1)}>
              {dictionary.CONTRACT_EDIT_PRODUCTS_BOX_LABEL}
            </h4>
            <p>{dictionary.CONTRACT_TEMPLATE_BOX_INSTRUCTIONS}</p>
            <div className={joinArgs(utilClasses.flex, utilClasses.flexWrap, utilClasses.alignCenter)}>
              <div className={utilClasses.mr3}>
                <CSVLink
                  data={escapeQuotesInDescription(contractWithEdits?.products ?? [])}
                  headers={csvHeadersPlusDelete}
                  filename={`contract_${contractId}_products.csv`}
                  className={joinArgs(buttonClasses.base, buttonClasses.leadingIcon, buttonClasses.reverse, utilClasses.mb1)}
                >
                  {!contractWithEdits?.products?.length ? <LoadingSpinner /> : <FontAwesomeIcon icon={faFileDownload} />}
                  {contractWithEdits?.products?.length ? dictionary.CONTRACT_PRODUCTS_DOWNLOAD_EXISTING : dictionary.CONTRACT_TEMPLATE_DOWNLOAD}
                </CSVLink>
              </div>
              <UploadEditsInput />
            </div>
          </div>
        </Col>
      </Row>

      <If condition={!!productSuccessCount}>
        <div className={joinArgs(utilClasses.fs18, utilClasses.fwBold, utilClasses.pt1, utilClasses.textTookishGreen)} data-testid="product-upload-success-msg">
          <FontAwesomeIcon icon={faCheckCircle} size="lg" className={utilClasses.pr05} />
          {productSuccessCount}
          {' '}
          {dictionary.CONTRACT_PRODUCTS_TOTAL_MESSAGE}
        </div>
      </If>

      <If condition={!!productDeleteCount}>
        <div className={joinArgs(utilClasses.fs18, utilClasses.fwBold, utilClasses.pt1, utilClasses.textGray)} data-testid="product-delete-success-msg">
          <FontAwesomeIcon icon={faCheckCircle} size="lg" className={utilClasses.pr05} />
          {productDeleteCount}
          {' '}
          {dictionary.CONTRACT_PRODUCTS_DELETE_MESSAGE}
        </div>
      </If>

      <If condition={!!productErrorCount}>
        <div className={joinArgs(utilClasses.fs18, utilClasses.fwBold, utilClasses.pt1, utilClasses.textRed)} data-testid="product-upload-failure-msg">
          {productErrorCount}
          {' '}
          {dictionary.CONTRACT_PRODUCTS_UPDATE_EXCEPTIONS_INSTRUCTIONS}
        </div>
        <ExceptionDownloadButton lines={contractWithEdits?.errorFailedLines ?? []} isEdit />
      </If>

      <Col xs={12} md={8} lg={6}>
        <FormActionsComponent onSubmitAction={onNextStepClicked} />
      </Col>
    </>
  );
};
